body{
  background-image: url("../../../static/img/landing-page-1.png");
    background-size: cover;
    background-position: center;
    background-repeat: repeat-y;
}

.magic {
  background-color: #ffffff;
  
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.magic .overlap-wrapper {
  overflow: hidden;
}

.magic .background{
  position: fixed;
    top: 0;
    left: 0;
  
    /* Preserve aspet ratio */
    min-width: 100%;
    min-height: 100%;
  }
  
  



.magic .div {
  background-color: #d9d9d9;
  height: 100px;
  min-width: 100px;
  position: relative;
}

.magic .audio {
  height: 96px;
  margin-top: -4841px;
  min-width: 96px;
  position: relative;
}

.magic .tell-us-about-your {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 844px;
  letter-spacing: 0;
  line-height: 45px;
  position: absolute;
  top: 3065px;
}

.magic .stream-creations-of {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 546px;
  letter-spacing: 0;
  line-height: 45px;
  position: absolute;
  text-align: center;
  top: 3894px;
}

.magic .group {
  height: 95px;
  left: 664px;
  position: absolute;
  top: 687px;
  width: 460px;
}

.magic .overlap-group {
  height: 95px;
  left: -2px;
  position: relative;
  top: -2px;
  width: 456px;
}

.magic .text-wrapper {
  -webkit-text-stroke: 2px #000000;
  color: #020cfc;
  font-family: "Sunflower", Helvetica;
  font-size: 160px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 45px;
  position: absolute;
  text-align: center;
  top: 9px;
  width: 449px;
}

.magic .text-wrapper-2 {
  -webkit-text-stroke: 2px #000000;
  color: #7000ff;
  font-family: "Sunflower", Helvetica;
  font-size: 160px;
  font-weight: 500;
  left: 7px;
  letter-spacing: 0;
  line-height: 45px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 449px;
}

.magic .text-wrapper-3 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 211px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 111px;
  white-space: nowrap;
}

.magic .vector {
  height: 31px;
  left: 245px;
  position: absolute;
  top: 79px;
  width: 29px;
}

.magic .text-wrapper-4 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 364px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 111px;
  white-space: nowrap;
}

.magic .text-wrapper-5 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 499px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 111px;
  white-space: nowrap;
}

.magic .rectangle-2 {
  background-color: #fff403;
  border: 2px solid;
  border-color: #000000;
  border-radius: 50px;
  height: 40px;
  left: 1150px;
  position: absolute;
  top: 91px;
  width: 124px;
}

.magic .rectangle-3 {
  background-color: #fff403;
  border: 2px solid;
  border-color: #000000;
  border-radius: 50px;
  height: 40px;
  left: 729px;
  position: absolute;
  top: 972px;
  width: 124px;
}

.magic .rectangle-4 {
  background-color: #ff54bb;
  border: 2px solid;
  border-color: #000000;
  border-radius: 50px;
  height: 40px;
  left: 1311px;
  position: absolute;
  top: 91px;
  width: 124px;
}

.magic .text-wrapper-6 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 1339px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 100px;
  white-space: nowrap;
}

.magic .text-wrapper-7 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 1187px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 99px;
  white-space: nowrap;
}

.magic .you-can-create {
  color: #000000;
  font-family: "Road Rage", Helvetica;
  font-size: 200px;
  font-weight: 400;
  left: 391px;
  letter-spacing: 0;
  line-height: 45px;
  position: absolute;
  text-align: center;
  top: 502px;
  width: 805px;
}

.magic .span {
  color: #000000;
  font-family: "Road Rage", Helvetica;
  font-size: 200px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 45px;
}

.magic .text-wrapper-8 {
  font-size: 175px;
}

.magic .text-wrapper-9 {
  color: #000000;
  font-family: "Road Rage", Helvetica;
  font-size: 200px;
  font-weight: 400;
  left: 475px;
  letter-spacing: 0;
  line-height: 45px;
  position: absolute;
  text-align: center;
  top: 679px;
  white-space: nowrap;
}

.magic .flex-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 105px;
  left: 384px;
  position: absolute;
  top: 834px;
  width: 791px;
}

.magic .text {
  -webkit-text-stroke: 1px #000000;
  color: #fa0397;
  font-family: "Sunflower", Helvetica;
  font-size: 35px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 45px;
  position: relative;
  text-align: center;
  text-shadow: 0px 4px 0px #f9ff01;
  width: 791px;
}

.magic .text-wrapper-10 {
  color: #fa0397;
  font-family: "Sunflower", Helvetica;
  font-size: 35px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 45px;
}

.magic .span-wrapper {
  -webkit-text-stroke: 1px #000000;
  color: #fa0397;
  font-family: "Sunflower", Helvetica;
  font-size: 35px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 45px;
  position: relative;
  text-align: center;
  text-shadow: 0px 4px 0px #f9ff01;
  white-space: nowrap;
  width: 791px;
}

.magic .rectangle-5 {
  background-color: #fdf9f9;
  border: 4px solid;
  border-color: #000000;
  border-radius: 20.28px;
  height: 595px;
  left: 47px;
  position: absolute;
  top: 2982px;
  width: 661px;
}

.magic .rectangle-6 {
  background-color: #ff87f3;
  border: 4px solid;
  border-color: #000000;
  border-radius: 20.28px;
  height: 475px;
  left: 78px;
  position: absolute;
  top: 3009px;
  width: 599px;
}

.magic .rectangle-7 {
  background-color: #ffffff;
  border: 4px solid;
  border-color: #000000;
  border-radius: 50px;
  height: 1072px;
  left: 186px;
  position: absolute;
  top: 4205px;
  width: 1210px;
}

.magic .rectangle-8 {
  background-color: #f2da08;
  border: 4px solid;
  border-color: #000000;
  border-radius: 40px;
  height: 903px;
  left: 220px;
  position: absolute;
  top: 4233px;
  width: 1140px;
}

.magic .text-wrapper-11 {
  color: #000000;
  font-family: "Road Rage", Helvetica;
  font-size: 100px;
  font-weight: 400;
  left: 1042px;
  letter-spacing: 0;
  line-height: 45px;
  position: absolute;
  text-align: center;
  top: 1336px;
  white-space: nowrap;
}

.magic .text-wrapper-12 {
  color: #000000;
  font-family: "Road Rage", Helvetica;
  font-size: 100px;
  font-weight: 400;
  left: 50px;
  letter-spacing: 0;
  line-height: 45px;
  position: absolute;
  text-align: center;
  top: 2161px;
  white-space: nowrap;
}

.magic .text-wrapper-13 {
  color: #000000;
  font-family: "Road Rage", Helvetica;
  font-size: 100px;
  font-weight: 400;
  left: 844px;
  letter-spacing: 0;
  line-height: 45px;
  position: absolute;
  text-align: center;
  top: 2981px;
  white-space: nowrap;
}

.magic .text-wrapper-14 {
  color: #000000;
  font-family: "Road Rage", Helvetica;
  font-size: 100px;
  font-weight: 400;
  left: 602px;
  letter-spacing: 0;
  line-height: 45px;
  position: absolute;
  text-align: center;
  top: 3805px;
  white-space: nowrap;
}

.magic .rectangle-9 {
  background-color: #ffffff;
  border: 4px solid;
  border-color: #000000;
  border-radius: 20.28px 0px 0px 20.28px;
  height: 475px;
  left: 76px;
  position: absolute;
  top: 1374px;
  width: 132px;
}

.magic .at-capes-we-believe {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 1043px;
  letter-spacing: 0;
  line-height: 45px;
  position: absolute;
  top: 1421px;
  width: 612px;
}

.magic .create-your-own {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 47px;
  letter-spacing: 0;
  line-height: 45px;
  position: absolute;
  top: 2245px;
}

.magic .rectangle-10 {
  background-color: #f992d0;
  border-radius: 30px 30px 0px 0px;
  height: 81px;
  left: 284px;
  position: absolute;
  top: 3316px;
  width: 75px;
}

.magic .img {
  height: 165px;
  left: 1432px;
  position: absolute;
  top: 3314px;
  width: 591px;
}

.magic .rectangle-11 {
  background-color: #ffffff;
  height: 774px;
  left: 0px;
  position: absolute;
  top: 5553px;
  width: 100vw;
}

.magic .line {
  height: 5px;
  left: 0px;
  position: absolute;
  top: 5548px;
  width: 100vw;
}

.magic .top-guys {
  height: 308px;
  left: 364px;
  position: absolute;
  top: 195px;
  width: 892px;
}

.magic .line-2 {
  height: 3px;
  left: 589px;
  position: absolute;
  top: 1454px;
  width: 178px;
}

.magic .hand {
  height: 521px;
  left: 82px;
  position: absolute;
  top: 2958px;
  width: 595px;
}

.magic .sillies-zero {
  height: 814px;
  left: 209px;
  position: absolute;
  top: 4169px;
  width: 1161px;
}

.magic .line-3 {
  height: 169px;
  left: 218px;
  position: absolute;
  top: 4752px;
  width: 1144px;
}

.magic .overlap-group-wrapper {
  height: 69px;
  left: 316px;
  position: absolute;
  top: 4951px;
  width: 992px;
}

.magic .overlap-2 {
  height: 69px;
  position: relative;
}

.magic .overlap-3 {
  height: 69px;
  left: 0;
  position: absolute;
  top: 0;
  width: 992px;
}

.magic .overlap-4 {
  height: 28px;
  left: 0;
  position: absolute;
  top: 41px;
  width: 992px;
}

.magic .rectangle-12 {
  background-color: #0000001a;
  border-radius: 15px;
  height: 10px;
  left: 0;
  position: absolute;
  top: 8px;
  width: 992px;
}

.magic .ellipse {
  background-color: #ffffff;
  border-radius: 14px;
  height: 28px;
  left: 55px;
  position: absolute;
  top: 0;
  width: 28px;
}

.magic .play-arrow {
  height: 46px;
  left: 465px;
  position: absolute;
  top: 1px;
  width: 46px;
}

.magic .fast-forward {
  height: 47px;
  left: 526px;
  position: absolute;
  top: 0;
  width: 47px;
}

.magic .fast-rewind {
  height: 43px;
  left: 401px;
  position: absolute;
  top: 3px;
  width: 43px;
}

.magic .audio-2 {
  height: 35px;
  left: 957px;
  position: absolute;
  top: 6px;
  width: 35px;
}

.magic .div-wrapper {
  height: 36px;
  left: 116px;
  position: absolute;
  top: 3413px;
  width: 517px;
}

.magic .overlap-5 {
  height: 36px;
  position: relative;
}

.magic .overlap-6 {
  height: 36px;
  left: 0;
  position: absolute;
  top: 0;
  width: 517px;
}

.magic .overlap-7 {
  height: 15px;
  left: 0;
  position: absolute;
  top: 21px;
  width: 517px;
}

.magic .rectangle-13 {
  background-color: #0000001a;
  border-radius: 7.82px;
  height: 5px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 517px;
}

.magic .ellipse-2 {
  background-color: #ffffff;
  border-radius: 7.3px;
  height: 15px;
  left: 29px;
  position: absolute;
  top: 0;
  width: 15px;
}

.magic .play-arrow-2 {
  height: 24px;
  left: 242px;
  position: absolute;
  top: 1px;
  width: 24px;
}

.magic .fast-forward-2 {
  height: 24px;
  left: 274px;
  position: absolute;
  top: 0;
  width: 24px;
}

.magic .fast-rewind-2 {
  height: 22px;
  left: 209px;
  position: absolute;
  top: 2px;
  width: 22px;
}

.magic .audio-3 {
  height: 18px;
  left: 499px;
  position: absolute;
  top: 3px;
  width: 18px;
}

.magic .twitter {
  height: 25px;
  left: 10px;
  position: absolute;
  top: 5708px;
  width: 25px;
}

.magic .instagram {
  height: 24px;
  left: 65px;
  position: absolute;
  top: 5708px;
  width: 24px;
}

.magic .facebook {
  height: 24px;
  left: 120px;
  position: absolute;
  top: 5708px;
  width: 24px;
}

.magic .vector-2 {
  height: 20px;
  left: 175px;
  position: absolute;
  top: 5710px;
  width: 20px;
}

.magic .us-dollar-circled {
  height: 37px;
  left: 378px;
  position: absolute;
  top: 76px;
  width: 37px;
}

.magic .organization {
  height: 37px;
  left: 532px;
  position: absolute;
  top: 77px;
  width: 37px;
}

.magic .text-wrapper-15 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 700;
  left: 760px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 980px;
  white-space: nowrap;
}

.magic .group-2 {
  background-color: #ffffff;
  border: 2.04px solid;
  border-color: #000000;
  border-radius: 25.5px;
  height: 51px;
  left: 10px;
  position: absolute;
  top: 77px;
  width: 51px;
}

.magic .overlap-8 {
  background-image: url(../../../static/img/rectangle-3304.svg);
  background-size: 100% 100%;
  height: 30px;
  left: 10px;
  position: relative;
  top: 15px;
  width: 30px;
}

.magic .overlap-group-2 {
  height: 14px;
  left: 3px;
  position: absolute;
  top: 6px;
  width: 26px;
}

.magic .ellipse-3 {
  background-color: #ffffff;
  border: 1.53px solid;
  border-color: #000000;
  border-radius: 6.38px;
  height: 13px;
  left: 0;
  position: absolute;
  top: 0;
  width: 13px;
}

.magic .ellipse-4 {
  background-color: #000000;
  border: 0.73px solid;
  border-radius: 3.06px;
  height: 6px;
  left: 4px;
  position: absolute;
  top: 5px;
  width: 6px;
}

.magic .ellipse-5 {
  background-color: #ffffff;
  border: 0.37px solid;
  border-color: #000000;
  border-radius: 1.53px;
  height: 3px;
  left: 11px;
  position: absolute;
  top: 11px;
  width: 3px;
}

.magic .ellipse-6 {
  background-color: #ffffff;
  border: 1.53px solid;
  border-color: #000000;
  border-radius: 6.38px;
  height: 13px;
  left: 13px;
  position: absolute;
  top: 0;
  width: 13px;
}

.magic .ellipse-7 {
  background-color: #000000;
  border: 0.73px solid;
  border-radius: 3.06px;
  height: 6px;
  left: 15px;
  position: absolute;
  top: 5px;
  width: 6px;
}

.magic .ellipse-8 {
  height: 7px;
  left: 6px;
  position: absolute;
  top: 21px;
  width: 18px;
}

.magic .text-wrapper-16 {
  color: #000000;
  font-family: "Road Rage", Helvetica;
  font-size: 40px;
  font-weight: 400;
  left: 67px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 82px;
  white-space: nowrap;
}

.magic .rectangle-14 {
  background-color: #ffe713;
  border-radius: 30px 30px 30px 0px;
  height: 65px;
  left: 115px;
  position: absolute;
  top: 3340px;
  width: 65px;
}

.magic .mask-group {
  background-image: url(../../../static/img/group-10.png);
  background-size: 100% 100%;
  height: 50px;
  left: 123px;
  position: absolute;
  top: 3347px;
  width: 50px;
}

.magic .community-support {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 449px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 5712px;
  width: 81px;
}

.magic .text-wrapper-17 {
  font-weight: 700;
}

.magic .text-wrapper-18 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
}

.magic .legal-privacy-center {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 584px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 5712px;
  width: 107px;
}

.magic .group-3 {
  height: 621px;
  left: 76px;
  position: absolute;
  top: 1316px;
  width: 756px;
}

.magic .overlap-9 {
  height: 621px;
  position: relative;
}

.magic .rectangle-15 {
  background-color: #fdf9f9;
  border: 4px solid;
  border-color: #000000;
  border-radius: 20.28px;
  height: 595px;
  left: 95px;
  position: absolute;
  top: 26px;
  width: 661px;
}

.magic .rectangle-16 {
  background-color: #ffffff;
  border: 4px solid;
  border-color: #000000;
  border-radius: 20.28px;
  height: 475px;
  left: 124px;
  position: absolute;
  top: 58px;
  width: 599px;
}

.magic .happy-cloud {
  height: 108px;
  left: 358px;
  position: absolute;
  top: 235px;
  width: 108px;
}

.magic .rectangle-17 {
  border: 4px solid;
  border-color: #000000;
  border-radius: 30px;
  height: 40px;
  left: 568px;
  position: absolute;
  top: 237px;
  width: 100px;
}

.magic .rectangle-18 {
  border: 4px solid;
  border-color: #000000;
  border-radius: 30px;
  height: 40px;
  left: 568px;
  position: absolute;
  top: 306px;
  width: 100px;
}

.magic .rectangle-19 {
  border: 4px solid;
  border-color: #000000;
  border-radius: 30px;
  height: 40px;
  left: 568px;
  position: absolute;
  top: 373px;
  width: 100px;
}

.magic .ellipse-9 {
  background-color: #faff07;
  border: 3px solid;
  border-color: #000000;
  border-radius: 12px;
  height: 24px;
  left: 633px;
  position: absolute;
  top: 246px;
  width: 24px;
}

.magic .ellipse-10 {
  background-color: #faff07;
  border: 3px solid;
  border-color: #000000;
  border-radius: 12px;
  height: 24px;
  left: 578px;
  position: absolute;
  top: 314px;
  width: 24px;
}

.magic .ellipse-11 {
  background-color: #faff07;
  border: 3px solid;
  border-color: #000000;
  border-radius: 12px;
  height: 24px;
  left: 633px;
  position: absolute;
  top: 383px;
  width: 24px;
}

.magic .group-4 {
  background-color: #ffffff;
  border: 4.6px solid;
  border-color: #000000;
  border-radius: 57.5px;
  height: 115px;
  left: 58px;
  position: absolute;
  top: 0;
  width: 115px;
}

.magic .overlap-group-3 {
  background-image: url(../../../static/img/rectangle-3304-1.svg);
  background-size: 100% 100%;
  height: 67px;
  left: 23px;
  position: relative;
  top: 34px;
  width: 68px;
}

.magic .overlap-group-4 {
  height: 32px;
  left: 6px;
  position: absolute;
  top: 13px;
  width: 58px;
}

.magic .ellipse-12 {
  background-color: #ffffff;
  border: 3.45px solid;
  border-color: #000000;
  border-radius: 14.38px;
  height: 29px;
  left: 0;
  position: absolute;
  top: 0;
  width: 29px;
}

.magic .ellipse-13 {
  background-color: #000000;
  border: 1.66px solid;
  border-radius: 6.9px;
  height: 14px;
  left: 8px;
  position: absolute;
  top: 10px;
  width: 14px;
}

.magic .ellipse-14 {
  background-color: #ffffff;
  border: 0.83px solid;
  border-color: #000000;
  border-radius: 3.45px;
  height: 7px;
  left: 25px;
  position: absolute;
  top: 25px;
  width: 7px;
}

.magic .ellipse-15 {
  background-color: #ffffff;
  border: 3.45px solid;
  border-color: #000000;
  border-radius: 14.38px;
  height: 29px;
  left: 29px;
  position: absolute;
  top: 0;
  width: 29px;
}

.magic .ellipse-16 {
  background-color: #000000;
  border: 1.66px solid;
  border-radius: 6.9px;
  height: 14px;
  left: 34px;
  position: absolute;
  top: 10px;
  width: 14px;
}

.magic .ellipse-17 {
  height: 15px;
  left: 14px;
  position: absolute;
  top: 47px;
  width: 41px;
}

.magic .rectangle-20 {
  background-color: #d9d9d980;
  border-radius: 30px;
  height: 50px;
  left: 58px;
  position: absolute;
  top: 179px;
  width: 150px;
}

.magic .rectangle-21 {
  background-color: #d9d9d980;
  border-radius: 30px;
  height: 50px;
  left: 0;
  position: absolute;
  top: 253px;
  width: 150px;
}

.magic .rectangle-22 {
  background-color: #d9d9d980;
  border-radius: 30px;
  height: 50px;
  left: 59px;
  position: absolute;
  top: 327px;
  width: 150px;
}

.magic .group-5 {
  height: 927px;
  left: 591px;
  position: absolute;
  top: 2127px;
  width: 1082px;
}

.magic .overlap-10 {
  height: 927px;
  position: relative;
  width: 864px;
}

.magic .rectangle-23 {
  background-color: #fdf9f9;
  border: 4px solid;
  border-color: #000000;
  border-radius: 20.28px;
  height: 595px;
  left: 92px;
  position: absolute;
  top: 35px;
  width: 661px;
}

.magic .rectangle-24 {
  height: 475px;
  left: 123px;
  position: absolute;
  top: 65px;
  width: 108px;
}

.magic .sillies {
  height: 475px;
  left: 263px;
  object-fit: cover;
  position: absolute;
  top: 65px;
  width: 455px;
}

.magic .rectangle-25 {
  border: 0.5px solid;
  border-color: #000000;
  height: 45px;
  left: 574px;
  position: absolute;
  top: 453px;
  transform: rotate(-78deg);
  width: 102px;
}

.magic .ellipse-18 {
  background-color: #3796ffbf;
  border-radius: 5px;
  height: 10px;
  left: 652px;
  position: absolute;
  top: 426px;
  width: 10px;
}

.magic .ellipse-19 {
  background-color: #3796ffbf;
  border-radius: 5px;
  height: 10px;
  left: 609px;
  position: absolute;
  top: 416px;
  width: 10px;
}

.magic .ellipse-20 {
  background-color: #3796ffbf;
  border-radius: 5px;
  height: 10px;
  left: 587px;
  position: absolute;
  top: 514px;
  width: 10px;
}

.magic .ellipse-21 {
  background-color: #3796ffbf;
  border-radius: 5px;
  height: 10px;
  left: 629px;
  position: absolute;
  top: 523px;
  width: 10px;
}

.magic .group-6 {
  height: 589px;
  left: 0;
  position: absolute;
  top: 64px;
  width: 721px;
}

.magic .overlap-group-5 {
  height: 589px;
  position: relative;
  width: 719px;
}

.magic .rectangle-26 {
  border: 4px solid;
  border-color: #000000;
  border-radius: 20.28px;
  height: 475px;
  left: 120px;
  position: absolute;
  top: 0;
  width: 599px;
}

.magic .rectangle-27 {
  background-color: #ffffff;
  border-radius: 18.18px;
  box-shadow: 0px 4.85px 24.24px 12.12px #0000000d;
  height: 297px;
  left: 0;
  position: absolute;
  top: 292px;
  width: 297px;
}

.magic .ellipse-22 {
  height: 158px;
  left: 69px;
  position: absolute;
  top: 351px;
  width: 158px;
}

.magic .ellipse-23 {
  height: 79px;
  left: 69px;
  position: absolute;
  top: 351px;
  width: 158px;
}

.magic .ellipse-24 {
  height: 79px;
  left: 92px;
  position: absolute;
  top: 351px;
  width: 135px;
}

.magic .rectangle-28 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #000000;
  border-radius: 10px;
  height: 30px;
  left: 214px;
  position: absolute;
  top: 537px;
  width: 65px;
}

.magic .rectangle-29 {
  background-color: #d9d9d9;
  border-radius: 10px;
  height: 5px;
  left: 129px;
  position: absolute;
  top: 303px;
  width: 40px;
}

.magic .rectangle-30 {
  background: linear-gradient(180deg, rgb(232.69, 76.59, 226.18) 0%, rgba(224, 56, 217, 0) 100%);
  border: 1px solid;
  border-color: #cdc7c7;
  border-radius: 10px;
  height: 30px;
  left: 14px;
  position: absolute;
  top: 537px;
  width: 150px;
}

.magic .ellipse-25 {
  background-color: #ea3ef7;
  border: 3px solid;
  border-color: #ffffff;
  border-radius: 12.5px;
  height: 25px;
  left: 151px;
  position: absolute;
  top: 539px;
  width: 25px;
}

.magic .ellipse-26 {
  background-color: #ea3ef7;
  border: 3px solid;
  border-color: #ffffff;
  border-radius: 13px;
  height: 26px;
  left: 40px;
  position: absolute;
  top: 484px;
  width: 26px;
}

.magic .ellipse-27 {
  background-color: #ea3ef7;
  border: 2.05px solid;
  border-color: #ffffff;
  border-radius: 8.55px;
  height: 17px;
  left: 178px;
  position: absolute;
  top: 478px;
  width: 17px;
}

.magic .ellipse-28 {
  background-color: #ffffff;
  border: 0.5px solid;
  border-color: #000000;
  border-radius: 10.5px;
  height: 21px;
  left: 42px;
  position: absolute;
  top: 350px;
  width: 21px;
}

.magic .text-wrapper-19 {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 13.7px;
  font-weight: 400;
  left: 228px;
  letter-spacing: 0;
  line-height: 6.2px;
  position: absolute;
  text-align: center;
  top: 548px;
  white-space: nowrap;
}

.magic .group-7 {
  background-color: #ffffff;
  border: 4.6px solid;
  border-color: #000000;
  border-radius: 57.5px;
  height: 115px;
  left: 46px;
  position: absolute;
  top: 0;
  width: 115px;
}

.magic .overlap-11 {
  background-image: url(../../../static/img/rectangle-3304-2.svg);
  background-size: 100% 100%;
  height: 67px;
  left: 23px;
  position: relative;
  top: 34px;
  width: 68px;
}

.magic .hand-2 {
  height: 658px;
  left: 463px;
  position: absolute;
  top: 269px;
  width: 401px;
}

.magic .product-download {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 338px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 5712px;
}

.magic .rectangle-31 {
  background-color: #04ff59;
  border: 4px solid;
  border-color: #000000;
  border-radius: 14.32px;
  box-shadow: 0px 4px 20px #00000040;
  height: 335px;
  left: 463px;
  position: absolute;
  top: 1123px;
  width: 423px;
}

.magic .movi-couple {
  height: 369px;
  left: 370px;
  position: absolute;
  top: 1230px;
  width: 403px;
}

.magic .rectangle-32 {
  background-color: #dddddd99;
  height: 17px;
  left: 1976px;
  position: absolute;
  top: 1176px;
  width: 106px;
}

.magic .rectangle-33 {
  background-color: #b9b9b999;
  height: 32px;
  left: 520px;
  position: absolute;
  top: 1202px;
  width: 311px;
}
